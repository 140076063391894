<template>
  <q-form ref="editForm">
    <c-card title="상세" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- <c-btn 
            v-if="editable && !isOld" 
            label="불러오기" 
            icon="system_update_alt"
            @btnClicked="loadAssessPlan" /> -->
          <c-btn 
            v-if="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="assessPlan"
            :mappingType="mappingType"
            label="저장" 
            icon="save"
            @beforeAction="saveAssessPlan"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            label="평가명"
            name="assessmentName"
            v-model="assessPlan.assessmentName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :range="true"
            label="평가기간"
            name="period"
            v-model="assessPlan.assessmentPeriod"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            type="year"
            label="평가년도"
            name="assessmentYear"
            v-model="assessPlan.assessmentYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            codeGroupCd="RAM_ASSESS_TYPE_CD"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="ramAssessTypeCd"
            label="평가구분-정기/수시/최초"
            v-model="assessPlan.ramAssessTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :comboItems="matrixItems"
            type="edit"
            itemText="matrixName"
            itemValue="ramMatrixId"
            name="ramMatrixId"
            label="Matrix"
            v-model="assessPlan.ramMatrixId"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field 
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :data="assessPlan"
            deptValue="assessmentManageDeptCd"
            type="dept_user" 
            label="주관" 
            name="assessmentManageUserId" 
            v-model="assessPlan.assessmentManageUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant 
            :required="true" 
            :disabled="disabled"
            :editable="editable" 
            type="edit" 
            name="plantCd" 
            v-model="assessPlan.plantCd"
             />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-moc 
            :editable="editable"
            :disabled="disabled"
            :isSubmit="saveCallData"
            :document.sync="assessPlan"
            :documentId="assessPlan.ramRiskAssessmentPlanId ? assessPlan.ramRiskAssessmentPlanId : ramRiskAssessmentPlanId"
            documentTitle="assessmentName"
            mocRelatedTaskCd="RT00000005"
            label="MOC번호"
            name="sopMocId"
            v-model="assessPlan.sopMocId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            :disabled="true"
            label="작성자/작성일"
            name="request"
            v-model="request">
          </c-text>
        </div>
        <div class="col-12">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="상세내용"
            name="remark"
            v-model="assessPlan.remark">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field 
            :editable="editable"
            :disabled="disabled"
            :data="assessPlan"
            type="user" 
            label="검토자" 
            name="reviewUserId" 
            v-model="assessPlan.reviewUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field 
            :editable="editable"
            :disabled="disabled"
            :data="assessPlan"
            type="user" 
            label="승인자" 
            name="approvalUserId" 
            v-model="assessPlan.approvalUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-vendor
            :disabled="disabled"
            :editable="editable"
            label="외부기관"
            name="vendorCd"
            v-model="assessPlan.vendorCd" />
        </div>
        <div class="col-12">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable&&!disabled">
          </c-upload>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'checklist-plan-info',
  props: {
    assessPlan: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        plantCd: '',  // 공사현장 코드
        ramTechniqueCd: '',  // 평가기법 코드_HAZOP/KRAS/JSA/CHECKLIST
        ramAssessTypeCd: '',  // 평가구분_최초/정기/수시
        assessmentName: '',  // 평가명
        assessmentStartDate: '',  // 평가시작일
        assessmentEndDate: '',  // 평가종료일
        assessmentPeriod: [],
        assessmentYear: '',  // 평가년도
        ramStepCd: '',  // 평가 진행단계 코드
        assessmentManageDeptCd: '',  // 평가주관 부서 코드
        assessmentManageUserId: '',  // 평가주관 담당자 ID
        ramMatrixId: null,  // 위험메트릭스 번호
        sopMocId: '',  // MOC 일련번호
        remark: '',  // 상세내용
        regUserId: '',  // 작성자 ID
        regUserName: '',  // 작성자 ID
        reviewUserId: '',  // 검토자 ID
        reviewDate: '',  // 검토일
        approvalUserId: '',  // 승인자 ID
        approvalDate: '',  // 승인일
        vendorCd: '',
        chgUserId: '',  // 수정자 ID
      }),
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'CHECKLIST_PLAN',
        taskKey: '',
      }),
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        flag: false,
      }),
    },
  },
  data() {
    return {
      matrixItems: [],
      editable: true,
      matrixListUrl: '',
      saveUrl: '',
      isSave: false,
      mappingType: 'POST',
      ramRiskAssessmentPlanId: '',
      saveCallData: '',
      popupOptions: {
        isApproval: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
    'assessPlan.plantCd'() {
      this.setMatrixItems(); 
    }
  },
  computed: {
    isOld() {
      return Boolean(this.assessPlan.ramRiskAssessmentPlanId)
    },
    request() {
      return this.assessPlan.regUserName + ' / ' + this.assessPlan.regDt
    },
    disabled() {
      return Boolean(this.assessPlan.ramStepCd) && this.assessPlan.ramStepCd !== 'RRS0000001' && !this.updateBtnData.flag
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.matrixListUrl = selectConfig.ram.matrix.list.url
      this.saveUrl = transactionConfig.ram.assessPlan.insert.url
      // code setting
      this.setMatrixItems(); 
      // list setting
    },
    setMatrixItems() {
      this.$http.url = this.matrixListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.assessPlan.plantCd,
        ramTechniqueCd: this.assessPlan.ramTechniqueCd,
      }
      this.$http.request((_result) => {
        this.matrixItems = _result.data;
      },);
    },
    loadAssessPlan() {
      this.popupOptions.title = "불러오기"; // 불러오기
      this.popupOptions.param = {
        type: "single",
        ramTechniqueCd: 'RT00000010',
        regUserId: this.$store.getters.user.userId
      };
      this.popupOptions.target = () => import(`${"@/pages/ram/loadAssessmentPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLoadPopup;
    },
    closeLoadPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$emit('loadAssess', data[0].ramRiskAssessmentPlanId)
      }
    },
    saveAssessPlan() {
      if (this.assessPlan.ramRiskAssessmentPlanId) {
        this.saveUrl = transactionConfig.ram.assessPlan.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.ram.assessPlan.insert.url
        this.mappingType = 'POST';
        this.assessPlan.ramStepCd = 'RRS0000001' // 평가계획
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.assessPlan.regUserId = this.$store.getters.user.userId
              this.assessPlan.chgUserId = this.$store.getters.user.userId

              this.assessPlan.assessmentStartDate = this.assessPlan.assessmentPeriod[0]
              this.assessPlan.assessmentEndDate = this.assessPlan.assessmentPeriod[1]

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.ramRiskAssessmentPlanId = result.data
      this.$set(this.attachInfo, 'taskKey', result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.saveCallData = uid();
      this.$emit('saveCallback', result.data)
    },
  }
};
</script>
